
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmFormLine,
    TmModal,
    TmButton,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'New widget',
    },
    btnTitle: {
      type: String,
      default: 'Create',
    },
    name: {
      type: String,
      default: '',
    },
    domain: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const nameValue = ref(props.name)
    const domainValue = ref(props.domain)

    return {
      nameValue,
      domainValue,
    }
  },
})
