import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "webWidgets",
    "modal-title": _ctx.modalTitle,
    size: "small"
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_tm_form_line, {
        label: "Widget name",
        class: "mb-4",
        required: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.nameValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nameValue) = $event)),
            placeholder: "Enter widget name"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_tm_form_line, { label: "Your website domain" }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.domainValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.domainValue) = $event)),
            placeholder: "company.com",
            message: "The domain of the website where the widget will be used. If you leave this field blank, the widget will work on any domain. We recommend adding your domain here for security reasons."
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        size: "large",
        color: "primary",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'base.chats.settings.webWidgets.edit.create' })))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnTitle), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-title"]))
}